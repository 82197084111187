/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: wang hai yang
 * @Descripttion: 用户管理模块接口
 */
import HTTP from "@utils/http";
const PREFIX = "/school/schclass/"; // 班级管理
const PREFIX2 = "/school/stuuser/"; // 学生管理
const PREFIX3 = "/school/teauser/"; // 教师管理

/* 班级管理 */

/*******
 * @Descripttion: 班级列表(树)
 * @Author: Dyf
 * @return {*}
 */
export async function $classTree() {
  try {
    return await HTTP.post(`${PREFIX}list`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增班级
 * @Author: Dyf
 * @param {object} params 班级新增参数
 * @return {*}
 */
export async function $classAdd(params) {
  try {
    return await HTTP.post(`${PREFIX}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑班级
 * @Author: Dyf
 * @param {object} params 班级编辑参数
 * @return {*}
 */
export async function $classEdit(params) {
  try {
    return await HTTP.post(`${PREFIX}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除班级
 * @Author: Dyf
 * @param {number} id 班级id
 * @return {*}
 */
export async function $classDel(id) {
  try {
    return await HTTP.post(`${PREFIX}del`, { id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 配置年级
 * @Author: Dyf
 * @param {object} params 年级配置参数
 * @return {*}
 */
export async function $gradeSet(params) {
  try {
    return await HTTP.post(`${PREFIX}edit_grade`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取年级配置信息
 * @Author: Dyf
 * @param {number} schedi_sub_grade 年级编码
 * @return {*}
 */
export async function $getGrad(schedi_sub_grade) {
  try {
    return await HTTP.post(`${PREFIX}grade_info`, { schedi_sub_grade });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 获取年级人员
 * @Author: ldx0.0
 * @param {number} grade_id 年级id sccla_id 班级id
 * @return {*}
 */
export async function $getGradeUserList(param) {
  try {
    return await HTTP.get(`${PREFIX}get_grade_user_list`, param );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 修改该班级人员
 * @Author: ldx0.0
 * @param 
 * @return {*}
 */
export async function $editElectiveForm(param) {
  try {
    return await HTTP.post(`${PREFIX}edit_elective_user`, param );
  } catch (error) {
    return error;
  }
}

/* 学生管理 */

/*******
 * @Descripttion: 学生列表
 * @Author: WHG
 * @return {*}
 */
export async function studentsList(params) {
  try {
    return await HTTP.get(`${PREFIX2}list`, params, { load: true });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 添加学生
 * @Author: WHG
 * @return {*}
 */
export async function studentsAdd(params) {
  try {
    return await HTTP.post(`${PREFIX2}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑学生
 * @Author: WHG
 * @return {*}
 */
export async function studentsEdit(params) {
  try {
    return await HTTP.post(`${PREFIX2}edit`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 学生详情
 * @Author: WHG
 * @return {*}
 */
export async function studentsInfo(params) {
  try {
    return await HTTP.post(`${PREFIX2}info`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 学生学情信息
 * @Author: WHG
 * @return {*}
 */
export async function getUserStudy(params) {
  try {
    return await HTTP.post(`${PREFIX2}get_user_study`, params);
  } catch (error) {
    return error;
  }
}

/******* 
 * @Descripttion: 学生经历年级学期
 * @Author: Dyf
 * @param {number} stuser_id 学生id
 * @return {*}
 */
export async function $studentGrade(stuser_id) {
  try {
    return await HTTP.post(`${PREFIX2}grade_semester`, {stuser_id});
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 查看家长
 * @Author: WHG
 * @return {*}
 */
export async function studentsSelectParent(params) {
  try {
    return await HTTP.post(`${PREFIX2}select_parent`, params);
  } catch (error) {
    return error;
  }
}

/* 教师管理 */

/*******
 * @Descripttion: 教师列表
 * @Author: WHG
 * @return {*}
 */
export async function teauserList(params) {
  try {
    return await HTTP.post(`${PREFIX3}list`, params, { load: true });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 对应教师下拉信息
 * @Author: WHG
 * @return {*}
 */
export async function teauserOptions(params) {
  try {
    return await HTTP.post(`${PREFIX3}options`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 年度已经存在的科目
 * @Author: WHG
 * @return {*}
 */
export async function teauserClassSub(params) {
  try {
    return await HTTP.post(`${PREFIX3}get_calss_sub`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 添加教师
 * @Author: WHG
 * @return {*}
 */
export async function teauserAdd(params) {
  try {
    return await HTTP.post(`${PREFIX3}add`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 编辑教师
 * @Author: WHG
 * @return {*}
 */
export async function teauserEdit(params) {
  try {
    return await HTTP.post(`${PREFIX3}edit`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 删除教师
 * @Author: WHG
 * @return {*}
 */
export async function teauserRemove(params) {
  try {
    return await HTTP.post(`${PREFIX3}delete`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 教师详情
 * @Author: WHG
 * @return {*}
 */
export async function teauserInfo(params) {
  try {
    return await HTTP.post(`${PREFIX3}getTeacherInformation`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教师详情
 * @Author: WHG
 * @return {*}
 */
export async function downloadTemplate(params) {
  try {
    return await HTTP.post(`${PREFIX3}downloadTemplate`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教师详情
 * @Author: WHG
 * @return {*}
 */
export async function importTeacher(params) {
  try {
    return await HTTP.post(`${PREFIX3}import`, params);
  } catch (error) {
    return error;
  }
}
